<template>
  <div class="flex flex-col space-y-2">
    <span>
      {{ label }}
    </span>
    <div
      v-tooltip="{
        content: value,
        onShow: () => textOverflows
      }"
      class="flex items-start space-x-2"
    >
      <div ref="textContainer">
        <info-span-copy
          :text="value"
          :disabled="!clickToCopy"
          :data-cy="id"
          class="flex justify-start text-gray-700 w-full"
        />

        <span
          ref="text"
          class="relative -z-5 whitespace-nowrap text-transparent"
        >
          {{ value || "-" }}
        </span>
      </div>
      <block-phone-actions
        v-if="id.includes('phone') && value && value !== '-'"
        class="mt-[10px]"
        :telephone-info="telephoneInfo"
        :contact-name="phoneContactName"
        :is-business="isBusiness"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import InfoSpanCopy from "@/views/deals/components/InfoSpanCopy.vue";
import BlockPhoneActions from "@/views/deals/components/BlockPhoneActions.vue";
import { useDealsBase } from "@/hooks/deals";
import { formatUserFullName } from "@/helpers/formatting";

const props = defineProps({
  /**
   * Allow user to click to copy field value
   */
  clickToCopy: {
    type: Boolean,
    default: false
  },
  /**
   * Identifier for copy function
   */
  id: {
    type: String,
    default: ""
  },
  /**
   * Required label for field
   */
  label: {
    type: String,
    default: ""
  },
  /**
   * Required value for field
   */
  value: {
    type: [String, Number],
    default: ""
  },

  isBusiness: {
    type: Boolean,
    default: false
  }
});

const { activeDeal } = useDealsBase();

const textContainer = ref<HTMLElement | null>(null);
const text = ref<HTMLElement | null>(null);

const textOverflows = computed(
  () =>
    !!textContainer.value &&
    !!text.value &&
    text.value.offsetWidth > textContainer.value.clientWidth
);

const businessTelephonesInfo = computed(
  () => activeDeal.value?.business?.all_telephones || []
);

const personalTelephonesInfo = computed(() => {
  const ownerInfo =
    activeDeal.value?.personal_information?.all_telephones || [];
  const otherOwnersInfo =
    activeDeal.value?.business?.other_owners?.flatMap(
      ({ all_telephones }) => all_telephones
    ) || [];
  return ownerInfo.concat(otherOwnersInfo);
});

const telephoneInfo = computed(() => {
  if (!props.id.includes("phone") || !props.value) {
    return null;
  }

  const businessTelephoneInfo =
    businessTelephonesInfo.value.find(
      (phone) => phone.number === props.value
    ) || null;

  const personalTelephoneInfo =
    personalTelephonesInfo.value.find(
      (phone) => phone.number === props.value
    ) || null;

  return props.isBusiness ? businessTelephoneInfo : personalTelephoneInfo;
});

const phoneContactName = computed(() => {
  if (props.isBusiness) {
    return activeDeal.value?.business?.business_legal_name || "";
  }

  return formatUserFullName(activeDeal.value.personal_information);
});
</script>
